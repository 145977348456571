import styled from "styled-components";
import breakpoints from "../../styling/breakpoints";
import FooterUnderline from "../../images/footerUnderline.svg";
import colors from "../../styling/colors";

export const Layout = styled.footer`
  position: relative;
  padding: 48px 0;

  border-top: 1px solid ${colors.dark};

  @media (min-width: ${breakpoints.small}) {
    border-top: none;

    ::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 30px;
      background-size: 102%;
      background-position-x: center;
      background-repeat: no-repeat;
      background-image: url(${FooterUnderline});
      top: -10px;
    }
  }
  @media (min-width: ${breakpoints.medium}) {
    ::before {
      top: -8px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.large}) {
    flex-direction: row;
  }
`;

export const Article = styled.article`
  width: 100%;
  text-align: center;

  & + & {
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.large}) {
    width: 25%;
    text-align: initial;

    & + & {
      margin-top: 0;
      margin-left: 24px;
    }

    & > & {
      width: 100%;
      margin-left: 0;
    }
  }
`;

export const Title = styled.h2`
  font-family: Cookie;
  font-size: 24px;
  margin-bottom: 12px;
  color: ${colors.darkBlue};
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  font-weight: 300;
  line-height: 24px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${colors.dark};
  transition: color 0.2s;

  :hover {
    color: ${colors.blue};
  }
`;

export const LinkIcon = styled(Link)`
  font-size: 20px;
  & + & {
    margin-left: 8px;
  }
`;
