import styled, { css } from "styled-components";
import background from "../images/background.webp";
import ChiefBorder from "../images/ChiefBorder.svg";
import breakpoints from "./breakpoints";
import colors from "./colors";

const SectionLayout = styled.section<{ withBackground?: boolean }>`
  overflow: hidden;
  padding: 24px 0 48px 0;
  ${({ withBackground }) =>
    withBackground &&
    css`
      background-image: url(${background});
      position: relative;

      border-top: 1px solid ${colors.lightBlue};
      border-bottom: 1px solid ${colors.lightBlue};

      @media (min-width: ${breakpoints.small}) {
        border: none;
        ::before,
        ::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          height: 12px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url(${ChiefBorder});
        }

        ::before {
          top: -1px;
        }

        ::after {
          bottom: -1px;
          transform: rotate(180deg);
        }
      }
    `}
`;

export default SectionLayout;
