import styled from "styled-components";
import HeaderUnderline from "../../images/HeaderUnderline.svg";
import breakpoints from "../../styling/breakpoints";
import colors from "../../styling/colors";

export const Layout = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 20;
  padding: 8px 0;
  padding-top: 0;

  border-bottom: 1px solid ${colors.dark};

  @media (min-width: ${breakpoints.small}) {
    padding: 16px 0 8px;
    padding-top: 0;
    border: none;
    ::before {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;
      height: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(${HeaderUnderline});

      bottom: -10px;
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    ::before {
      bottom: -8px;
    }
  }
`;

export const HiddenTitle = styled.h1`
  display: none;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Logo = styled.img<{ size: string }>`
  transition: width 0.2s;

  width: ${({ size }) => size};
`;

export const InformationBanner = styled.div`
  padding: 24px 0;
  background-color: ${colors.lightBlue};
  margin-bottom: 8px;
  color: white;

  @media (min-width: ${breakpoints.small}) {
    margin-bottom: 16px;
    text-align: center;
  }
`;
