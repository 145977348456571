import React from "react";
import { Link, List, ListElement, Nav } from "./desktopNav.styles";
import { scrollTo } from "../../headers.utils";

const DesktopNav = () => {
  return (
    <Nav>
      <List>
        <ListElement>
          <Link
            href="#presentation"
            onClick={(e) => scrollTo(e, "presentation")}
          >
            Présentation
          </Link>
        </ListElement>
        <ListElement>
          <Link href="#chief" onClick={(e) => scrollTo(e, "chief")}>
            Équipe
          </Link>
        </ListElement>
        <ListElement>
          <Link href="#menu" onClick={(e) => scrollTo(e, "menu")}>
            Carte
          </Link>
        </ListElement>
        <ListElement>
          <Link href="#book" onClick={(e) => scrollTo(e, "book")}>
            Réserver
          </Link>
        </ListElement>
      </List>
    </Nav>
  );
};

export default DesktopNav;
