import styled, { css } from "styled-components";
import breakpoints from "./breakpoints";

const Container = styled.div<{ fullWidth?: boolean }>`
  max-width: 100%;
  margin: auto;
  padding: 0 8px;
  height: 100%;

  @media (min-width: ${breakpoints.small}) {
    max-width: 100%;
    padding: 0 16px;
  }

  @media (min-width: ${breakpoints.medium}) {
    max-width: 100%;
    padding: 0 24px;
  }

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      @media (min-width: ${breakpoints.large}) {
        max-width: 900px;
      }

      @media (min-width: ${breakpoints.extra}) {
        max-width: 1200px;
      }
    `}
`;

export default Container;
