import React, { useEffect, useState } from "react";
import { BrowserView } from "react-device-detect";
import Container from "../../styling/container";
import DesktopNav from "./components/desktopNav/desktopNav";
import {
  HiddenTitle,
  InformationBanner,
  Layout,
  Logo,
  Wrapper,
} from "./header.styles";

import LogoSvg from "../../images/logo.svg";

const Header = () => {
  const [isSmall, setIsSmall] = useState<boolean>(false);

  useEffect(() => {
    window.onscroll = () => {
      setIsSmall(window.scrollY > 620);
    };
  }, []);

  const size = isSmall ? "50px" : "90px";

  const information = "";

  return (
    <Layout id="header">
      {information && (
        <InformationBanner>
          <Container>{information}</Container>
        </InformationBanner>
      )}
      <Container>
        <Wrapper>
          <Logo size={size} src={LogoSvg} alt="Logo de Version Originale" />
          <HiddenTitle aria-hidden="true">Version Originale</HiddenTitle>
          <BrowserView>
            <DesktopNav />
          </BrowserView>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Header;
