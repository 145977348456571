import styled from "styled-components";
import breakpoints from "../../../../styling/breakpoints";
import colors from "../../../../styling/colors";

export const Article = styled.article`
  @media (min-width: ${breakpoints.small}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Picture = styled.picture`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 49%;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (min-width: ${breakpoints.small}) {
    width: 150px;

    & + & {
      margin-left: 12px;
    }
  }

  @media (min-width: ${breakpoints.large}) {
    width: 200px;
  }
`;

export const Paragraph = styled.p`
  flex: 1
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  margin-top: 32px;

  @media (min-width: ${breakpoints.small}) {
    margin-top: 0;
    max-width: 300px;
    margin-left: 38px;
    text-align: left;
  }
`;

export const ButtonLink = styled.a`
  display: block;
  background: ${colors.blue};
  border-radius: 4px;
  padding: 8px 6px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: box-shadow 0.2s;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 14px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${colors.dark};
  transition: color 0.2s;

  :hover {
    color: ${colors.blue};
  }
`;
