import styled, { css } from "styled-components";
import breakpoints from "../../../../styling/breakpoints";
import colors from "../../../../styling/colors";
import { lighten } from "polished";

export const Subtitle = styled.p`
  text-align: center;
  max-width: 600px;
  margin: auto;
  margin-bottom: 24px;
  font-weight: 300;
  line-height: 24px;
`;

export const List = styled.div``;

export const MenuItem = styled.div`
  text-align: center;
  border: 1px solid ${lighten(0.5, colors.blue)};
  border-radius: 2px;
  padding: 20px;
  flex: 1;
`;

export const MenuItemLayout = styled.article`
  margin: auto;
  border: 1px solid ${colors.blue};
  border-radius: 4px;
  padding: 4px;
  display: flex;

  & + & {
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.large}) {
    width: 60%;
    & + & {
      margin-top: 0;
      margin-left: 40px;
    }
  }
`;

export const MenuTitle = styled.p`
  font-family: Cookie;
  font-size: 28px;
  color: ${colors.blue};
`;

export const MenuPart = styled.div`
  margin: 16px 0;
`;

export const MenuPartTitle = styled.p`
  font-family: Cookie;
  font-size: 24px;
  margin-bottom: 12px;
`;

export const MenuPartItem = styled.p<{ withSpace?: boolean }>`
  font-weight: 300;
  line-height: 24px;

  ${({ withSpace }) =>
    withSpace &&
    css`
      margin: 12px 0;
    `}
`;

export const MenuPartItemPrice = styled.span`
  padding-left: 4px;
  font-weight: 400;
`;

export const InfoParagraph = styled.p`
  margin-top: 24px;
  font-weight: 300;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
`;

export const MenuSelector = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
`;

export const MenuButton = styled.button<{ isCurrentSelection: boolean }>`
  font-family: Cookie;
  font-size: 22px;
  color: ${({ isCurrentSelection }) =>
    isCurrentSelection ? colors.lightBlue : colors.dark};

  margin: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
  background: none;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    width: 0%;
    border-bottom: 1px solid ${colors.lightBlue};
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s, width 0.2s;
  }

  :hover {
    color: ${colors.lightBlue};

    ::before {
      width: 40%;
      opacity: 1;
    }
  }

  ${({ isCurrentSelection }) =>
    isCurrentSelection &&
    css`
      color: ${colors.lightBlue};

      ::before {
        width: 40%;
        opacity: 1;
      }
    `}

  transition: color 0.2s;
`;

export const Picture = styled.picture`
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;

  @media (min-width: ${breakpoints.small}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Image = styled.img<{ moreHeight?: boolean }>`
  width: 100%;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  ${({ moreHeight }) =>
    moreHeight &&
    css`
      grid-row: auto / span 2;
    `}
`;

export const Line = styled.hr`
  border: 0;
  border-top: 1px solid ${lighten(0.5, colors.blue)};
  margin: 32px auto;
  width: 50%;
`;

export const ButtonLink = styled.a`
  display: inline-block;
  background: ${colors.blue};
  border-radius: 4px;
  padding: 8px 16px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: box-shadow 0.2s;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 14px;
  }
`;
