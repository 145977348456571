export const scrollTo = (event: React.MouseEvent, to: string) => {
  event.preventDefault();
  const header = document.getElementById("header");
  const element = document.getElementById(to);
  if (element && header) {
    try {
      window.scrollTo({
        top: element.offsetTop - header.offsetHeight,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(element.offsetTop - 110, 0);
    }
  }
};
