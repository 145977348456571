import { createGlobalStyle } from "styled-components";
import colors from "./colors";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: content-box;
  }

  body {
    font-family: "Montserrat";
    color: ${colors.dark};
  }

  h1, h2, h3, h4 {
    font-weight: bold;
  }
`;
