import React from "react";
import Container from "../../../../styling/container";
import SectionLayout from "../../../../styling/sectionLayout";
import {
  Article,
  Section,
  Picture,
  PictureWrapper,
  ValueSpan,
  Paragraph,
  Line,
  MiddleImage,
  TopImage,
  BottomImage,
  Title,
  DownImage,
  MidSection,
  Rating,
  RatingLink,
  RatingParagraph,
  RatingStars,
} from "./presentation.styles";

import p1 from "../../../../images/p1.webp";
import p2 from "../../../../images/p2.webp";
import p3 from "../../../../images/p3.webp";
import p4 from "../../../../images/p4.webp";
import p5 from "../../../../images/p5.webp";
import { MdOutlineStar } from "react-icons/md";

export const Presentation = () => (
  <SectionLayout id="presentation">
    <Container>
      <Article>
        <PictureWrapper>
          <Picture>
            <TopImage
              src={p1}
              alt="Une table dressée"
              loading="lazy"
              decoding="async"
            />
            <MiddleImage
              src={p2}
              alt="Une table dressée"
              loading="lazy"
              decoding="async"
            />
            <BottomImage
              src={p3}
              alt="Vue d'une pièce du restaurant"
              loading="lazy"
              decoding="async"
            />
          </Picture>
        </PictureWrapper>
        <Section>
          <Title>Version Originale</Title>
          <Paragraph>
            C’est un espace cosy, un refuge chaleureux où il fait bon
            s’attarder. Situé à Pontarlier, à quelques pas du centre ville,
            votre restaurant Version Originale vous accueille dans un décor
            vintage chic et décontracté. Il présente une cuisine contemporaine
            et appliquée avec un sens affirmé du produit, des cuissons et des
            saveurs toujours plus recherchées. Nos valeurs :
          </Paragraph>
          <Paragraph>
            <ValueSpan>Manger</ValueSpan>
            Nous travaillons des produits bruts, locaux et de saison
            privilégiant la fraîcheur, cherchant l’équilibre en préparant le
            produit au plus proche de son naturel.
          </Paragraph>
          <Paragraph>
            <ValueSpan>Partager</ValueSpan>
            Nous vous accueillons dans un cadre privilégié et chaleureux au look
            rétro, rappelant à chacun un souvenir de sa plus tendre enfance.
            Cuisiner c’est partager !
          </Paragraph>
          <Paragraph>
            <ValueSpan>Bruncher</ValueSpan>
            Brunch le samedi midi pour les plus gourmands sous forme de buffet
            mêlant brunch de tradition : œufs brouillés, lard paysan, muesli,
            viennoiseries… et buffet tout en finesse : gravelax de saumon,
            velouté de légumes de saison, un plat chaud gourmand…
          </Paragraph>
          <Line />
          <Paragraph>
            Nous nous engageons à proposer une cuisine de saison, respectueuse
            des aliments et d’inspiration méditerranéenne.
          </Paragraph>
        </Section>
      </Article>
      <Article>
        <Section>
          <DownImage
            src={p4}
            alt="Plat proposé par le restaurant"
            loading="lazy"
            decoding="async"
          />
        </Section>
        <Section>
          <DownImage
            src={p5}
            alt="Plat proposé par le restaurant"
            loading="lazy"
            decoding="async"
          />
        </Section>
        <MidSection>
          <Rating>
            <RatingStars role="img" aria-label="Note Google : 4,5">
              <MdOutlineStar />
              <MdOutlineStar />
              <MdOutlineStar />
              <MdOutlineStar />
              <MdOutlineStar />
            </RatingStars>
            <RatingParagraph>
              <RatingLink
                href="https://www.google.com/search?q=avis+google+version+originale+pontarlier&rlz=1C5GCEM_enFR988FR989&oq=avis+google+version+originale+pontarlier&aqs=chrome..69i57.7601j0j7&sourceid=chrome&ie=UTF-8#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D&trex=m_t:lcl_akp,rc_ludocids:989862691834251790,rc_q:avis%2520google%2520version%2520originale%2520pontarlier,ru_gwp:0%252C7,ru_q:avis%2520google%2520version%2520originale%2520pontarlier,trex_id:AEZxhd"
                target="_blank"
              >
                +290 avis Google
              </RatingLink>
            </RatingParagraph>
          </Rating>
        </MidSection>
      </Article>
    </Container>
  </SectionLayout>
);

export default Presentation;
