import React from "react";
import type { HeadFC } from "gatsby";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Main from "../components/main/main";
import { Reset } from "styled-reset";
import { GlobalStyle } from "../styling/globalStyle";

const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      <Reset />
      <Header />
      <Main />
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Version Originale, restaurant à Pontarlier</title>
    <meta
      name="description"
      content="Votre restaurant bistronomique à Pontarlier. Cuisine locale et de saison, cadre convivial et chaleureux, brunch le dimanche. Nous proposons aussi des poke bowl à déguster sur place ou à emporter."
    />
  </>
);
