import React from "react";
import Container from "../../../../styling/container";
import SectionLayout from "../../../../styling/sectionLayout";
import SectionTitle from "../../../../styling/sectionTitle";
import {
  Article,
  Paragraph,
  ParagraphContent,
  ParagraphHeader,
  ParagraphImage,
  ParagraphText,
} from "./chief.styles";
import { MdOutlineStar, MdOutlineStarHalf } from "react-icons/md";

import c1 from "../../../../images/c1.webp";
import c2 from "../../../../images/c2.webp";

const Chief = () => (
  <SectionLayout withBackground id="chief">
    <Container>
      <SectionTitle color="dark">
        <span>Notre équipe</span>
      </SectionTitle>
      <Article>
        <Paragraph>
          Version Originale c’est Kévin et Manon, couple de restaurateurs riches
          d’expériences individuelles, qui enrichissent toujours plus notre
          quotidien… Et vos assiettes. Après des années tous deux en tant que
          saisonniers dans diverses établissements, diverses régions de France,
          c’est à Pontarlier qu’ils prennent racine.
        </Paragraph>
        <Paragraph after>
          <ParagraphContent>
            <ParagraphImage
              src={c1}
              alt="Image de Kévin, chef de cuisine"
              loading="lazy"
              decoding="async"
            />
            <ParagraphText>
              <ParagraphHeader>Kevin, chef de cuisine.</ParagraphHeader>
              Né en Vendée, j’ai très vite trouvé ma passion pour la cuisine.
              J’ai parfait mon expérience dans un restaurant étoilé en Loire
              Atlantique, dans divers établissements à Val Thorens, à Antibes,
              en Corse du Sud et chez moi sur la côte atlantique. Pour la
              première fois, je vais m’exprimer en tant que chef et y mettre
              toute mon expérience. Je vous propose une cuisine savoureuse et
              raffinée comme je l’aime.
            </ParagraphText>
          </ParagraphContent>
        </Paragraph>
        <Paragraph before>
          <ParagraphContent>
            <ParagraphText>
              <ParagraphHeader>Manon, responsable de salle.</ParagraphHeader>
              Native de Pontarlier, le service a été un de mes premiers emploi
              étudiant. C’est à ce moment là que je me suis rendue compte de ma
              passion pour la restauration, j’ai pris mon envol direction la
              Corse l’été, les Alpes l’hiver ! C’est avec un immense plaisir que
              je reviens dans ma région pour vous servir dans un décor et une
              ambiance qui m’est propre.
            </ParagraphText>
            <ParagraphImage
              src={c2}
              alt="Image de Manon, responsable de salle"
              loading="lazy"
              decoding="async"
            />
          </ParagraphContent>
        </Paragraph>
      </Article>
    </Container>
  </SectionLayout>
);

export default Chief;
