import styled from "styled-components";
import colors from "./colors";
import breakpoints from "./breakpoints";

import left from "../images/left.svg";
import right from "../images/right.svg";

const SectionTitle = styled.h2`
  position: relative;
  font-family: Cookie;
  font-size: 38px;
  color: ${colors.blue};
  text-align: center;
  margin: 12px 0 60px;

  span {
    @media (min-width: ${breakpoints.medium}) {
      position: relative;

      ::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -80px;
        transform: translateY(-50%);

        width: 60px;
        height: 15px;

        background-image: url(${left});
        background-repeat: no-repeat;
      }

      ::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -80px;
        transform: translateY(-50%);

        width: 60px;
        height: 15px;

        background-image: url(${right});
        background-repeat: no-repeat;
      }
    }
  }
`;

export default SectionTitle;
