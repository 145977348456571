import styled, { css } from "styled-components";
import breakpoints from "../../../../styling/breakpoints";
import colors from "../../../../styling/colors";

export const ParagraphHeader = styled.span`
  display: block;
  font-weight: 600;
  text-align: center;
`;

export const ParagraphImage = styled.img`
  width: 200px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.large}) {
    margin-left: -50px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

export const ParagraphContent = styled.p`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.large}) {
    flex-direction: row;
  }
`;

export const Paragraph = styled.div<{ before?: boolean; after?: boolean }>`
  max-width: 600px;
  margin: auto;
  line-height: 24px;
  font-weight: 300;
  position: relative;

  & + & {
    margin-top: 20px;
  }

  ${({ before }) =>
    before &&
    css`
      ${ParagraphContent} {
        flex-direction: column-reverse;

        @media (min-width: ${breakpoints.large}) {
          flex-direction: row;
        }
      }
    `}

  @media (min-width: ${breakpoints.large}) {
    ${({ before }) =>
      before &&
      css`
        ::before {
          font-family: cookie;
          content: "“";
          font-size: 200px;
          position: absolute;
          top: 85px;
          left: -30px;
          color: ${colors.blue};
          opacity: 0.5;
        }
      `}

    ${({ after }) =>
      after &&
      css`
        ::after {
          font-family: cookie;
          content: "“";
          font-size: 200px;
          position: absolute;
          top: 70px;
          right: -30px;
          color: ${colors.blue};
          opacity: 0.5;
          transform: rotateY(180deg);
        }
      `}
  }
`;

export const Article = styled.article``;

export const ParagraphText = styled.span`
  text-align: justify;

  @media (min-width: ${breakpoints.large}) {
    margin-right: 32px;

    ${ParagraphImage} + & {
      margin-right: 0px;
      margin-left: 32px;
    }

    & + ${ParagraphImage} {
      margin-left: 0px;
      margin-right: -50px;
    }
  }
`;
