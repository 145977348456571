import React from "react";
import Container from "../../../../styling/container";
import SectionLayout from "../../../../styling/sectionLayout";
import SectionTitle from "../../../../styling/sectionTitle";
import {
  Article,
  ButtonLink,
  Image,
  Paragraph,
  Picture,
  Link,
} from "./book.styles";
import b1 from "../../../../images/b1.webp";
import b2 from "../../../../images/b2.webp";

const Book = () => (
  <SectionLayout id="book">
    <Container>
      <SectionTitle color="dark">
        <span>Réserver une table</span>
      </SectionTitle>
      <Article>
        <Picture>
          <Image src={b1} alt="Image de plat" loading="lazy" decoding="async" />
          <Image src={b2} alt="Image de plat" loading="lazy" decoding="async" />
        </Picture>
        <Paragraph>
          Vous souhaitez découvrir notre cuisine ? Vous pouvez réserver en nous
          appelant au <Link href="tel:+33634521065">06.34.52.10.65</Link> entre
          8h et minuit.
          <ButtonLink href="tel:+33634521065">
            Réservez par téléphone
          </ButtonLink>
        </Paragraph>
      </Article>
    </Container>
  </SectionLayout>
);

export default Book;
