import React from "react";
import Book from "./components/book/book";
import Chief from "./components/chief/chief";
import Menu from "./components/menu/menu";
import Presentation from "./components/presentation/presentation";
import { scrollTo } from "../header/headers.utils";
import { FaChevronDown } from "react-icons/fa";
import { isMobile } from "react-device-detect";

import { AnimatedArrow, Button, ImageWrapper } from "./main.styles";
import { Parallax } from "react-parallax";

const Main = () => {
  return (
    <main>
      <Parallax
        bgImage={
          isMobile
            ? require("../../images/pbm.webp").default
            : require("../../images/pb.webp").default
        }
        bgImageStyle={isMobile ? { bottom: "0" } : undefined}
        bgImageAlt="Une table du restaurant"
        strength={200}
      >
        <ImageWrapper id="parallax-wrapper">
          <Button
            onClick={(e) => scrollTo(e, "presentation")}
            aria-hidden="true"
          >
            <AnimatedArrow tabIndex={-1}>
              <FaChevronDown tabIndex={-1} />
            </AnimatedArrow>
          </Button>
        </ImageWrapper>
      </Parallax>

      <Presentation />
      <Chief />
      <Menu />
      <Book />
    </main>
  );
};

export default Main;
