import { transparentize } from "polished";
import styled from "styled-components";
import breakpoints from "../../../../styling/breakpoints";
import colors from "../../../../styling/colors";

export const Article = styled.article`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  position: relative;

  @media (min-width: ${breakpoints.large}) {
    flex-direction: row;
  }

  & + & {
    margin-top: 32px;
  }
`;

export const MidSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  @media (min-width: ${breakpoints.large}) {
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 50%;
    width: 300px;
    background: ${transparentize(0.3, "white")};
    backdrop-filter: blur(10px);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateX(-50%);
    margin-top: 0;
  }
`;

export const Title = styled.h2`
  position: relative;
  font-family: Cookie;
  font-size: 38px;
  color: ${colors.blue};
  margin: 32px 0;
  text-align: center;

  @media (min-width: ${breakpoints.large}) {
    margin: 16px;
    text-align: left;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 16px;
  line-height: 24px;
  font-weight: 300;
  text-align: justify;

  @media (min-width: ${breakpoints.large}) {
    text-align: left;
  }
`;

export const ValueSpan = styled.span`
  display: block;
  font-size: 24px;
  font-family: Cookie;
  color: ${colors.blue};
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: ${breakpoints.large}) {
    text-align: left;
    margin-left: 16px;
  }
`;

export const Line = styled.hr`
  width: 20px;
  border: 0;
  border-bottom: 1px solid ${colors.blue};
  margin: 16px auto;
`;

export const PictureWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.large}) {
    justify-content: flex-start;
  }
`;

export const Section = styled.section`
  @media (min-width: ${breakpoints.large}) {
    min-width: 50%;
    max-width: 50%;

    & + &,
    ${PictureWrapper} + & {
      margin-left: 24px;
    }
  }
`;

export const Picture = styled.picture`
  position: relative;
  height: 600px;
  width: 100%;

  @media (min-width: ${breakpoints.small}) {
    width: 450px;
    height: 700px;
  }
`;

export const Image = styled.img`
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.2s;
  position: absolute;

  & + & {
    margin-top: 8px;
  }

  @media (min-width: ${breakpoints.large}) {
    :hover {
      transform: scale(1.05);
    }
  }
`;

export const TopImage = styled(Image)`
  width: 60%;
  top: 0;
  left: 0;
  z-index: 1;
  @media (min-width: ${breakpoints.small}) {
    width: 250px;
  }
`;

export const MiddleImage = styled(Image)`
  width: 70%;
  top: 170px;
  right: 0;
  z-index: 2;
  @media (min-width: ${breakpoints.small}) {
    width: 300px;
    top: 200px;
  }
`;

export const BottomImage = styled(Image)`
  width: 60%;
  bottom: 0;
  left: 50px;
  z-index: 3;
  @media (min-width: ${breakpoints.small}) {
    width: 250px;
  }
`;

export const DownImage = styled.img`
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  height: 140px;
  object-fit: cover;
  margin-top: 12px;

  @media (min-width: ${breakpoints.small}) {
    margin-top: 0;
    height: 200px;
  }
`;

export const Rating = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RatingStars = styled.picture`
  color: ${colors.blue};
  font-size: 32px;
`;

export const RatingParagraph = styled.p`
  font-size: 14px;
  font-weight: 300;
`;

export const RatingLink = styled.a`
  color: ${colors.dark};
`;
