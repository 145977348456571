import styled from "styled-components";
import colors from "../../../../styling/colors";
import underline from "../../../../images/underline2.svg";
import noUnderline from "../../../../images/underline3.svg";

export const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 100%;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding: 8px 2px;
  height: 100%;

  transition: background-image 0.2s, color 0.2s;

  background-position: 50% 35px;
  background-repeat: no-repeat;
  background-size: 60px;
  background-image: url(${noUnderline});

  font-family: Cookie;
  font-size: 26px;
  color: ${colors.blue};
  text-decoration: none;

  :hover {
    color: ${colors.dark};
    background-image: url(${underline});
  }
`;

export const List = styled.ul`
  display: flex;
  height: 100%;
`;

export const ListElement = styled.li`
  display: flex;
  align-items: center;
`;
