import styled, { keyframes } from "styled-components";
import colors from "../../styling/colors";

export const ImageWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const arrowIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
`;

export const AnimatedArrow = styled.span`
  animation: 2s ${arrowIn} cubic-bezier(0, 0.6, 1, 0.3) infinite;
`;

export const Button = styled.button`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  border: 0;

  padding: 25px 30px 20px;
  margin: 0;

  display: flex;
  justify-content: center;

  border-radius: 100px 100px 0 0;

  cursor: pointer;
  color: ${colors.blue};
  background: white;

  transition: padding 0.2s;

  :hover {
    padding-top: 28px;
    padding-bottom: 24px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;
